import React, { useEffect, Fragment, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfilePdf, saveIncompleteList, clearApplication } from '../../actions/vermaster';
import Spinner from '../layout/Spinner';
import {Button, Row} from 'reactstrap';

const Pending = ({ 
  getProfilePdf, 
  saveIncompleteList, clearApplication,
  history, 
  auth: { user },
  profile: { profile, applnid, loading, getdb }
}) => {

    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []); 

  const [formData, setFormData] = useState({    
    bna: false,
    tenna: false,
    elena: false, elef: false,
    twef: false, twena: false, twiv: false,
    pcm: false, 
    tcna: false, tcf: false,
    indn: false,
    natif: false, natina: false,
    pona: false, 
    ccna: false, ccf: false, 
    dpna: false, dpf: false, exsf: false, exsna: false, govsna: false, govsin: false, slna: false, slf: false,
    fgna: false, inna: false,
    appid: ''
  });


  const {
    bna, tenna, elef, elena, twena, twef, pcm, tcna, tcf, indn, natina, natif, pona,
    ccf, ccna, dpf, dpna, exsf, exsna, twiv, govsna, govsin, slna, slf, fgna, inna
  } = formData;

  const onCan = async e => {
    clearApplication(navigate);    
    navigate("/tfcdash");
    formData.gappid = '';
  }

  const onSubmit = async e => {
    e.preventDefault(); 

    const tobesent = {};     

    tobesent._aid = applnid;
    tobesent.tfccode = user.tfccode;
    tobesent.username = user.username;
    tobesent.ltype = user.ltype; 
    tobesent.vflag = "incomplete";
    
    tobesent.bna = formData.bna;
    tobesent.tenna = formData.tenna;
    tobesent.elef = formData.elef;
    tobesent.elena = formData.elena;
    tobesent.twena = formData.twena;
    tobesent.twef = formData.twef;
    tobesent.twiv = formData.twiv;
    tobesent.pcm = formData.pcm;
//    tobesent.tcna = formData.tcna;
//    tobesent.tcf = formData.tcf;
    tobesent.indn = formData.indn;
    tobesent.natina = formData.natina;
    tobesent.natif = formData.natif;
    tobesent.pona = formData.pona;
    tobesent.ccf = formData.ccf;
    tobesent.ccna = formData.ccna;
    tobesent.dpf = formData.dpf;
    tobesent.dpna = formData.dpna;
    tobesent.exsf = formData.exsf;
    tobesent.exsna = formData.exsna;
    //tobesent.govsna = formData.govsna;
    //tobesent.govsin = formData.govsin;
    tobesent.slna = formData.slna;
    tobesent.slf = formData.slf;

    tobesent.inna = formData.inna;

    tobesent.fgna = formData.fgna;

    //console.log("on submit tobesent pending");    
    console.log(tobesent);
    await saveIncompleteList(tobesent);             

    console.log("redirecting to download");
    await getProfilePdf(applnid);
    
    navigate('/tfcdash');
  };
  

  return (
    <section className='container'>
    <Fragment>
  
      <h5 style={{textAlign: 'center', color: "black"}}> TFC Centre: {user && user.tfccode} - {user && user.tfcname} </h5>
  
      {loading === true && getdb === true ? (
        <Spinner /> ) : (
  <>  
      {profile !== null ? (
      <div>
      <div className="my-2 form" style={{textAlign: 'center', fontWeight: 'bolder', 
            color: 'blue', border: "2px ridge"}}>
        <h5 className="my-2"  style={{fontWeight: "bold"}}> SELECT LIST OF PENDING CERTIFICATES </h5>
      </div>
       
      <form className='form'> 
      <div className="" style={{textAlign: 'center', fontWeight: 'bolder', 
            color: 'black'}}>
        <h6 style={{fontWeight: "bold"}}> Application Number: {profile._aid} </h6>
        <h6 style={{fontWeight: "bold"}}>    Name: {profile._n} </h6>

        </div>       

        {profile.cv && profile.cv.upd !== null && profile.cv.upd !== undefined && profile.cv.upd.length > 0 &&
        <div className='profile' style={{fontWeight: "bold", color: "crimson", textAlign: "center"}}>
          <h6>Updation Officer remarks</h6>
          <h6> {profile.cv.upd[profile.cv.upd.length-1].ur } </h6>
        </div>
          }


        {profile.cv && profile.cv.icl !== null && profile.cv.icl !== undefined && profile.cv.icl.length > 0 &&
        <div className="profile-grid">

          <div style={{textAlign: "center", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}> Previous Incomplete List </div>          

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>           
              {profile.cv.icl[profile.cv.icl.length-1].bna !== false ? <div> *Bonafide Certificate (Nativity others)* </div> : null} 
          </div>

          
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].tenna !== false ? <div>  *Xth marksheet not available*  </div> : null} 
          </div>
          
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].elef !== false ? <div> *XIth: Fail in a Subject*  </div> : null}  
          </div>
          
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].elena !== false ? <div>  *XIth marksheet not available* </div> : null} 
          </div>
          
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].twena !== false ? <div>   *XIIth marksheet not available* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].twef !== false ? <div>  *XIIth: Fail in a Subject*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
          {profile.cv.icl[profile.cv.icl.length-1].twiv ? <div>  *XIIth: Invalid*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].pcm !== false ? <div>  *Physics, Chemistry, Mathematics not studied* </div> : null} 
          </div>
          

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].indn !== false ? <div>  *Nationality not indian*  </div> : null} 
          </div>
          
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].natina !== false ? <div>  *TN Nativity Certificate not available* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].natif !== false ? <div>  *TN Nativity Certificate not in format* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].pona !== false ? <div> *Parent Occupation Certificate not available*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].ccna !== false ? <div> *TN Community Certificate not available*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].ccf !== false ? <div>  *TN Community Certificate not in acceptable format* </div> : null} 
          </div>

         
          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].dpna !== false ? <div> *Disability Certificate not available*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].dpf !== false ? <div>  *Disability Certificate not in acceptable format* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].exsna !== false ? <div> *Ex-Serviceman Certificate not available*  </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].exsf !== false ? <div>  *Ex-Serviceman Certificate not in acceptable format* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].slna !== false ? <div>  *Srilankan Refugee Certificate not available* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].slf !== false ? <div>  *Srilankan Refugee Certificate not in acceptable format* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].fgna !== false ? <div>  *First Graduate Certificate not available* </div> : null} 
          </div>

          <div style={{textAlign: "left", paddingLeft: "80px", color: "darkblue", fontWeight: "bold"}}>
            {profile.cv.icl[profile.cv.icl.length-1].inna !== false ? <div>  *Income Certificate not available* </div> : null} 
          </div>


          </div>
          
        </div>
        }

        <div className="grid-5 profile2">              
            <label class="form-check-label"> 1.  10th MarkSheet </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='tenna'   checked={tenna}   value={tenna}   onChange={() => {
                setFormData({ ...formData, tenna: !tenna }); }} /> 
                                
        </div>
        
        <div className="profile2 even">              
            <label class="form-check-label"> 2.  11th MarkSheet </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='elena'   checked={elena}   value={elena}   onChange={() => {
                setFormData({ ...formData, elena: !elena }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Fail in a Subject</label>
            <input  type='checkbox'  name='elef'   checked={elef}   value={elef}   onChange={() => {
                setFormData({ ...formData, elef: !elef }); }} /> 
                                
        </div>

        <div className="profile6">              
            <label class="form-check-label"> 3.  12th MarkSheet </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='twena'   checked={twena}   value={twena}   onChange={() => {
                setFormData({ ...formData, twena: !twena }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Fail in a Subject</label>
            <input  type='checkbox'  name='twef'   checked={twef}   value={twef}   onChange={() => {
                setFormData({ ...formData, twef: !twef }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}> Invalid </label>
            <input  type='checkbox'  name='twiv'   checked={twiv}   value={twiv}   onChange={() => {
                setFormData({ ...formData, twiv: !twiv }); }} /> 
                                
        </div>

        <div className="grid-5 profile2 even">              
            <label class="form-check-label" > 4.  Physics, Chemistry, Maths </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Studied</label>
            <input  type='checkbox'  name='pcm'   checked={pcm}   value={pcm}   onChange={() => {
                setFormData({ ...formData, pcm: !pcm }); }} /> 
                                
        </div>

        <div className="profile2">              
            <label class="form-check-label"> 5. TN Community Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='ccna'   checked={ccna}   value={ccna}   onChange={() => {
                setFormData({ ...formData, ccna: !ccna }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Not in Acceptable Format </label> 
            <input  type='checkbox'  name='ccf'   checked={ccf}   value={ccf}   onChange={() => {
                setFormData({ ...formData, ccf: !ccf }); }} /> 
                                
        </div>


        <div className="grid-5 profile2 even">              
            <label class="form-check-label"> 6.  Nationality </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Indian </label>
            <input  type='checkbox'  name='indn'   checked={indn}   value={indn}   onChange={() => {
                setFormData({ ...formData, indn: !indn }); }} /> 
                                
        </div>

        <div className="profile2">              
            <label class="form-check-label"> 7. TN Nativity Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='natina'   checked={natina}   value={natina}   onChange={() => {
                setFormData({ ...formData, natina: !natina }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Not in Acceptable Format </label> 
            <input  type='checkbox'  name='natif'   checked={natif}   value={natif}   onChange={() => {
                setFormData({ ...formData, natif: !natif }); }} /> 
                                
        </div>

        <div className="grid-5 profile2 even">              
            <label class="form-check-label"> 8.  Parent Occupation Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available </label>
            <input  type='checkbox'  name='pona'   checked={pona}   value={pona}   onChange={() => {
                setFormData({ ...formData, pona: !pona }); }} /> 
                                
        </div>

        <div className="profile2">              
            <label class="form-check-label"> 9.  Bonafide Certificate (Nativity others) </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='bna'   checked={bna}   value={bna}   onChange={() => {
                setFormData({ ...formData, bna: !bna }); }} /> 
                                
        </div> 


        <div className="profile2 even">              
            <label class="form-check-label"> 10.  Differently abled Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='dpna'   checked={dpna}   value={dpna}   onChange={() => {
                setFormData({ ...formData, dpna: !dpna }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Not in Acceptable Format </label> 
            <input  type='checkbox'  name='dpf'   checked={dpf}   value={dpf}   onChange={() => {
                setFormData({ ...formData, dpf: !dpf }); }} /> 
                                
        </div>

        <div className="profile2">              
            <label class="form-check-label"> 11.  Ex-Serviceman Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='exsna'   checked={exsna}   value={exsna}   onChange={() => {
                setFormData({ ...formData, exsna: !exsna }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Not in Acceptable Format </label> 
            <input  type='checkbox'  name='exsf'   checked={exsf}   value={exsf}   onChange={() => {
                setFormData({ ...formData, exsf: !exsf }); }} /> 
                                
        </div>

        <div className="profile2 even">              
            <label class="form-check-label"> 12.  Srilankan Refugee Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='slna'   checked={slna}   value={slna}   onChange={() => {
                setFormData({ ...formData, slna: !slna }); }} /> 
            <label class="form-check-label" style={{textAlign: "right"}}>Not in Acceptable Format </label> 
            <input  type='checkbox'  name='slf'   checked={slf}   value={slf}   onChange={() => {
                setFormData({ ...formData, slf: !slf }); }} /> 
                                
        </div>

        <div className="profile2">              
            <label class="form-check-label"> 13.  First Graduate Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='fgna'   checked={fgna}   value={fgna}   onChange={() => {
                setFormData({ ...formData, fgna: !fgna }); }} /> 
        </div>

        <div className="profile2 even">              
            <label class="form-check-label"> 14.  Income Certificate </label>
            <label class="form-check-label" style={{textAlign: "right"}}>Not Available</label>
            <input  type='checkbox'  name='inna'   checked={inna}   value={inna}   onChange={() => {
                setFormData({ ...formData, inna: !inna }); }} /> 
        </div>
      
      
      <div className='' style={{alignItems: "center", textAlign: "center"}}>  
      <button className="btn btn-success" value="Save Changes" onClick= {e => onSubmit(e)}> Save Changes </button>  
      <button type="submit" className="btn btn-dark" value="Cancel" onClick= {e => onCan(e)}> Cancel </button>  
      </div>

      </form>

      </div>
      ) : null }

</> ) }

    </Fragment>
    </section>
  );
};

Pending.propTypes = {
  getProfilePdf: PropTypes.func.isRequired,
  saveIncompleteList: PropTypes.func.isRequired,
  clearApplication: PropTypes.func.isRequired,

  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getProfilePdf, saveIncompleteList, clearApplication }
)(Pending);